import { Card, List, Space, Typography, Button, Divider } from 'antd';
import React, { useMemo } from 'react';
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useApprovedBy from '@aims/shared/reports/use-approved-by';
import useSubmittedBy from '@aims/shared/reports/use-submitted-by';
import ReportInfoItem from '@aims/shared/reports/common/ReportInfoItem';
import settings from '../../../settings';
import { Link } from 'react-router-dom';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { getQuarterLabel } from '@aims/shared/reports/quarters';

const { Title, Text } = Typography;

function ReportListItem({
  report,
  locale,
  holisticProjectId,
  canCreateReports,
  user,
}) {
  const status = ReportApprovalStatuses[report.status];

  const submittedBy = useSubmittedBy(report);
  const approvedBy = useApprovedBy(report);
  const quarter = getQuarterLabel(report.quarter);

  const finances = useMemo(() => {
    const exchangeRate = getNumberValue(report.exchangeRate);
    if (exchangeRate > 0) {
      const supportToFieldWorkers = (report.supportedFieldWorkers || []).reduce(
        (prev, curr) => {
          return prev + getNumberValue(curr && curr.amountForeign);
        },
        0,
      );
      return {
        expenses:
          getNumberValue(report.expensesThisQuarterForeign) / exchangeRate,
        revenue:
          getNumberValue(report.revenueThisQuarterForeign) / exchangeRate,
        loanPayments: getNumberValue(report.loadPaymentsThisQuarterUsd),
        toFieldWorkers: supportToFieldWorkers / exchangeRate,
        received: report.hasReceivedFunds ? 'Yes' : 'No',
      };
    }
    return {};
  }, [report]);
  return (
    <List.Item style={{ border: 'none', width: '100%' }}>
      <Card style={{ width: '100%' }} bordered>
        <div className="row1">
          <Title level={4}>
            <Space>
              {quarter}
              {canCreateReports && (
                <>
                  <MinusOutlined />
                  <Text style={{ color: status?.color }}>{status?.label}</Text>
                </>
              )}
            </Space>
          </Title>
          <Space>
            <Link
              to={`/holistic-projects/${holisticProjectId}/reports/view/${report._id}`}
            >
              <Button icon={<EyeOutlined />}>View</Button>
            </Link>
            {canCreateReports && !report.isApproved && (
              <Link
                to={`/holistic-projects/${holisticProjectId}/reports/edit/${report._id}`}
              >
                <Button icon={<EditOutlined />}>Edit</Button>
              </Link>
            )}
          </Space>
        </div>
        <div style={{ maxWidth: 600 }}>
          <Divider>Financial Update</Divider>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Loan funds received',
                value: finances.received,
              },
              {
                label: 'Loan payments made',
                value: displayMoney(finances.loanPayments),
              },
              {
                label: 'Quarterly Revenue',
                value: displayMoney(finances.revenue),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Quarterly Expenses',
                value: displayMoney(finances.expenses),
              },
              {
                label: 'Support to Field Workers',
                value: displayMoney(finances.toFieldWorkers),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className="row3">
          <Text style={{ fontSize: 14 }}>{submittedBy}</Text>
        </div>
        <div className="row3">
          <Text style={{ fontSize: 14 }}>{approvedBy}</Text>
        </div>
      </Card>
      <style jsx>{`
        .row1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }
        .info {
          margin-bottom: 16px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
          max-width: 600px;
        }
      `}</style>
    </List.Item>
  );
}

export default ReportListItem;
