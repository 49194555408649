import { PgQuarterlyReportItems } from '@aims/shared/reports';
import {
  Button,
  Form,
  Input,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useReport from './use-report';

import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import { UpgXContactRoles } from '@aims/shared/people-groups/constants';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import Modal from 'antd/lib/modal/Modal';
import settings from '../../../settings';
import useAccount from '../../../shared/use-account';
import useMyPeopleGroups from '../use-my-pgs';
import usePeopleGroup from '../use-people-group';
import DeleteReportButton from './DeleteReportButton';
import {
  createUpgQuarterlyReportMutation,
  submitUpgQuarterlyReportMutation,
  unsubmitUpgQuarterlyReportMutation,
  updateUpgQuarterlyReportMutation,
} from './constants';

const { Title, Text } = Typography;

const queryId = 'editUpgQuarterlyReport';

function EditQuarterlyReport() {
  const { peopleGroupId, reportId } = useParams();
  const { myPgs } = useMyPeopleGroups();
  const myPg = myPgs.find((p) => p._id === peopleGroupId);
  const isFieldWorker =
    myPg?.roles?.length === 1 &&
    myPg?.roles?.[0] === UpgXContactRoles.FIELD_WORKER.key;
  const { data: peopleGroup, loading: loading1 } = usePeopleGroup(
    peopleGroupId,
    queryId,
  );
  const {
    report,
    loading: loading2,
    refetch,
  } = useReport(reportId, queryId, false);
  const account = useAccount();

  const { nameAcrossCountries, country } = peopleGroup || {};
  const peopleGroupName = `${nameAcrossCountries} - ${country}`;

  const [form] = Form.useForm();
  const finishSubForm = useRef();
  const shouldSubmitReport = useRef(false);
  const [saving, setSaving] = useState(false);
  const [updateReport] = useMutation(updateUpgQuarterlyReportMutation);
  const [createReport] = useMutation(createUpgQuarterlyReportMutation);
  const [submitReport] = useMutation(submitUpgQuarterlyReportMutation);
  const [unsubmitReport] = useMutation(unsubmitUpgQuarterlyReportMutation);
  const history = useHistory();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Report ...';
      } else if (
        ['unsubmit', 'resubmit'].includes(shouldSubmitReport.current)
      ) {
        msg = 'Unsubmitting Report ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaving(msg);
      const { notes, ..._values } = __values;
      try {
        const reportDetails = finishSubForm.current(_values);
        if (
          report &&
          ['unsubmit', 'resubmit'].includes(shouldSubmitReport.current)
        ) {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Unsubmitted',
            description: 'Report unsubmitted successfully',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: 'PG_QUARTERLY',
                notes,
                peopleGroupId,
                ...reportDetails,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report updated successfully',
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                reportType: 'PG_QUARTERLY',
                notes,
                peopleGroupId,
                ...reportDetails,
                contactId: reportDetails.contactId
                  ? reportDetails.contactId
                  : account._id,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report created successfully',
          });
        }
        if (['submit', 'resubmit'].includes(shouldSubmitReport.current)) {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Submitted',
            description: 'Report submitted successfully',
          });
          history.push(`/upgs/${peopleGroupId}/reports/view/${reportId}`);
        } else {
          refetch();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report.',
        });
      }
      setSaving(false);
    },
    [
      reportId,
      createReport,
      history,
      account,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
      peopleGroupId,
      refetch,
    ],
  );

  const loading = loading1 || loading2 || saving;
  const showDelete = report && !report.submittedAt;

  return (
    <CSPage title="AIMS.org | Edit Quarterly People Group Report">
      <CSPageHeader
        titleComponent={null}
        title="UPG Quarterly Report"
        parents={[
          {
            label: 'Unreached People Groups',
            link: `/upgs`,
          },
          {
            label: peopleGroupName,
            link: `/upgs/${peopleGroupId}/reports`,
          },
        ]}
      />
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="EditReportForm"
        form={form}
        style={{
          maxWidth: 800,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        {loading1 || loading2 ? (
          <>
            <Title level={3} style={{ textAlign: 'center' }}>
              UPG Quarterly Report
            </Title>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
          </>
        ) : (
          <>
            <PgQuarterlyReportItems
              _id={reportId}
              report={report}
              isFieldWorker={isFieldWorker}
              peopleGroup={peopleGroup}
              loading={loading}
              form={form}
              finishSubForm={finishSubForm}
              fieldsToSet={{
                peopleGroupId,
              }}
              showTitle
            />
          </>
        )}
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {(!report ||
              report?.status === ReportApprovalStatuses.DRAFT.key) && (
              <>
                <Button
                  key="draft"
                  type="dashed"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = undefined;
                    form.submit();
                  }}
                >
                  Save Draft
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = 'submit';
                    form.submit();
                  }}
                >
                  Submit Report
                </Button>
              </>
            )}
            {report?.status === ReportApprovalStatuses.PENDING.key && (
              <Button
                key="unsubmit"
                type="dashed"
                loading={loading}
                htmlType="button"
                onClick={() => {
                  shouldSubmitReport.current = 'unsubmit';
                  form.submit();
                }}
              >
                Unsubmit Report
              </Button>
            )}
            {report?.status === ReportApprovalStatuses.HOLD.key && (
              <Button
                key="resubmit"
                type="primary"
                loading={loading}
                htmlType="button"
                onClick={() => {
                  shouldSubmitReport.current = 'resubmit';
                  form.submit();
                }}
              >
                Re-Submit Report
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {showDelete && (
        <DeleteReportButton report={report} peopleGroupId={peopleGroupId} />
      )}
      <Modal open={saving} footer={null} closable={false} width={300}>
        <div style={{ textAlign: 'center', padding: 24 }}>
          <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
          <Title level={4}>{saving}</Title>
        </div>
      </Modal>
      <style jsx global>{`
        .church {
          border: 1px solid ${settings.colors.borderGray};
        }
      `}</style>
    </CSPage>
  );
}

export default EditQuarterlyReport;
