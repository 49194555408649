import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';

const TrainingEventReportFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    eventDate
    hostName
    hostWebsite
    eventAddress {
      label
      address1
      address2
      city
      state
      country
      zipCode
    }
    numPeopleTrained
    itsmModulesTaught
    trainersTrained {
      _id
      name
      email
      phone
      whatsApp
      country
      orgName
      potentialRole
    }
    newPartnerships {
      _id
      orgName
      orgDesc
      orgWebsite
      contactInfo {
        _id
        name
        email
        phone
        whatsApp
        country
        orgName
        potentialRole
      }
    }
    numUpgAdoptions
    newAdoptions {
      _id
      contactInfo  {
        _id
        name
        email
        phone
        whatsApp
        country
        orgName
        potentialRole
      }
      peopleGroupId
      peopleGroupName
    }
    miracles {
      _id
      desc
    }
  }
`;

const query = gql`
  query TrainingEventReportForUser($_id: ID!) {
    trainingEventReportForUser(_id: $_id) ${TrainingEventReportFullAttributes}
  }
`;

const extract = (data) => data?.trainingEventReportForUser;

const TrainingEventReportUpdateAttributes = gql`
{
  crud
  id
  new ${TrainingEventReportFullAttributes}
}
`;

export const trainingEventReportUpdatesSubscription = gql`
  subscription TrainingEventReportUpdatesForUser($_id: ID!) {
    trainingEventReportUpdatesForUser(_id: $_id) ${TrainingEventReportUpdateAttributes}
  }
`;

function useReport(_id, queryId = 'useTrainingEventReport', showError = true) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
    showError,
  });
  const report = useSingleSomethingA10('reports', _id, queryId);

  return {
    report,
    loading,
    refetch,
  };
}

export default useReport;
